<template>
  <div class="mt-4">

    <vx-tour
      name="questionnaireAnswersIntroSteps"
      :auto="true"
    />

    <div>

      <div class="grid grid-cols-1 sm:grid-cols-6 w-full">

        <div class="w-full flex justify-center">
          <div
            class="flex bg-gray-200"
            @click="getAnswers(true)"
            style="cursor: pointer;"
          >
            <div class="flex-4 text-center py-2 ml-2 tour-answers-refresh">
              <feather-icon
                icon="RefreshCwIcon"
                svgClasses="h-4 w-4"
                class="ml-1"
                v-tooltip.top="{
                  content: 'Atualizar',
                  delay: { show: 1000, hide: 100 }
                }"
              />
            </div>
            <div class="flex-4 text-center py-1 px-4">
              <b>{{ $t('atualizar') }}</b>
            </div>
          </div>
        </div>

        <div v-if="hasAnswers" class="w-full flex justify-center text-sm">
          <vs-checkbox
            v-model="filterCorrected"
            class="mb-3 tour-answers-1"
          >
            {{ $t('questao-sem-correcao') }}
          </vs-checkbox>
        </div>

        <div v-if="hasAnswers" class="flex flex-wrap w-full md:col-span-2 justify-center">
          <div class="flex bg-gray-200">
            <div class="flex-4 text-center py-1 px-2">
              <b>{{ $t('agrupado-por') }}</b>
            </div>
            <div class="flex-4 text-center py-1 px-2">{{ $t('usuario') }}</div>
            <div class="flex-4 text-center py-1 ml-2 tour-answers-2">
              <vs-switch v-model="questionGrouped" />
            </div>
            <div class="flex-4 text-center py-1 px-4">{{ $t('questao') }}</div>
          </div>
        </div>

        <div class="flex justify-end sm:justify-left py-1 px-4 w-full md:col-span-2 mt-4 md:mt-0">
            <label class="text-center font-bold" v-if="!isEmpty(usersList) && usersList.length > 0" >
              {{ $tc('x_answered_questionnaire', usersList.length, { count: usersList.length }) }}
            </label>
        </div>
      </div>

      <div class="flex justify-items-center w-full" v-if="unfinishedCount > 0 && !questionnaireClosed">
        <vs-alert
          :title="$t('alerta')"
          color="rgb(231, 154, 23)"
          active="true"
          class="mt-5 text-warning mb-4 h-auto">
           <b> {{unfinishedCount}} participante{{unfinishedCount > 1 ? 's':''}} ainda {{ unfinishedCount > 1 ? 'não finalizaram':'não finalizou' }} a sua avaliação. Caso já tenha encerrado o período de aplicação, orientamos: </b>

          <span class="flex items-center mt-2">
            <div class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-warning"></div>
            <span class="text-sm text-warning"> Situações que pode ocorrer para esse comportamento: participante não envio/finalizou a sua avaliação; fechou o aplicativo (navegador/browser); para avaliações com tempo, pode ser que o participante saiu da prova e o tempo foi encerrado. </span>
          </span>
          <span class="flex items-center mt-4">
            <div class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-warning"></div>
            <span class="text-sm text-warning"> Se você clicar em liberar notas no final da página, todos os questionários pendentes serão finalizados.</span>
          </span>
          <span class="flex items-center mt-4">
            <div class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-warning"></div>
            <span class="text-sm text-warning"> Para o caso de questionários finalizados, basta liberar novamente que serão automaticamente encerradas as avaliações dos participantes.</span>
          </span>
        </vs-alert>
      </div>

      <vs-row v-if="questionGrouped">
        <vs-col>
        <correction-question-page
          @showCommentModal="showCommentModal"
          :model="currentQuestion"
          :fields="contentQuestions"
          @showCompleteQuestionModal="showCompleteQuestionModal"
          :grid="grid"
        >
          <template v-slot:header class="w-full">
            <div class="grid grid-cols-4">
              <div class="col-span-4 lg:col-span-2">
                <vs-select
                  no-data-text=" "
                  v-if="currentQuestion"
                  autocomplete
                  :label="$t('questao')"
                  v-model="pagination.currentQuestion"
                >
                  <vs-select-item
                    :key="number"
                    :value="number+1"
                    :text="`Questão ${number + 1} - ${shortTitle(question.data.title)}`"
                    v-for="(question, number) in questionsFiltered" />
                </vs-select>
              </div>
              <div class="col-span-4 md:mt-1 lg:col-span-2">
                <div class="lg:pt-6 lg:w-1/2 lg:float-right">
                  <vs-pagination class="tour-answers-3" max="8" :total="questionsFiltered.length" v-model="pagination.currentQuestion"></vs-pagination>
                </div>
              </div>
            </div>
          </template>
        </correction-question-page>
        </vs-col>
      </vs-row>

      <vs-row v-if="!questionGrouped">
        <vs-col>
          <correction-user-page
            class="mb-4"
            :model="currentUser"
            :fields="contentQuestions"
            @secondChance="givenSecondChance"
            @showCompleteQuestionModal="showCompleteQuestionModal"
            @showCommentModal="showCommentModal"
          >
            <template v-slot:header class="w-full">
              <div class="grid grid-cols-6">
                <div class="col-span-6 mb-2 md:col-span-3 md:mb-0">
                  <vs-select
                    no-data=" "
                    class="select-auto-width"
                    v-if="currentUser"
                    autocomplete
                    :label="$t('usuario')"
                    v-model="pagination.currentUser"
                  >
                    <vs-select-item
                      :key="number"
                      :value="number+1"
                      :text="`${user.name} - ${user.email}`"
                      v-for="(user, number) in usersList" />
                  </vs-select>
                </div>
                <div class="col-span-6 md:col-span-3">
                    <div class="md:pt-5 md:w-1/2 md:float-right">
                      <vs-pagination max="5" :total="usersList.length" v-model="pagination.currentUser"></vs-pagination>
                    </div>
                </div>
              </div>
            </template>
          </correction-user-page>
        </vs-col>
      </vs-row>

      <vs-row v-if="noAnswers" class="flex text-center mt-4" >
        <vs-col class="w-full">{{ $t('nao-ha-respostas-disponiveis-clique') }}</vs-col>
      </vs-row>

      <vs-row class="flex text-center mt-4 mb-24" v-else>
        <vs-col class="flex w-1/2 float-left font-bold">{{
          !isEmpty(content) && !isEmpty(content.score_released_at)
            ? `Notas liberadas em ${content.score_released_at_formatted}`
            : ''
        }}</vs-col>
        <vs-col class="w-1/2 flex justify-end items-center gap-2">
          <div>
            <vs-button
              type="border"
              class="tour-answers-9"
              @click="reapplyCorrection"
              v-tooltip.bottom-start="{
                content: 'Processa todas as respostas enviadas e aplica a correção automática.',
                delay: { show: 1000, hide: 100 }
              }"
              > <font-awesome-icon style="height: 14px; width: 14px;" icon="cogs"/> {{ $t('atualizar-correcao') }}</vs-button
            >
          </div>

          <div>
            <vs-button
              v-permission="'questionnaires.release.notes'"
              class="tour-answers-9"
              icon="playlist_play"
              @click="scoreReleaseConfirmation"
              :disabled="disableScoreReleaseButton"
              v-tooltip.bottom-start="{
                content: 'Liberar notas do questionário',
                delay: { show: 1000, hide: 100 }
              }"
              >{{ $t('liberar-notas') }}</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
    </div>

    <vx-tour
      name="questionnaireAnswerHasAnswerSteps"
      :auto="false"
    />

    <vs-popup title :active.sync="showCompleteQuestion" :fullscreen="true">
      <correction-complete-question
        :complete-question="completeQuestion"
        :questionnaire-answer-service="questionnaireAnswerService"
      />
    </vs-popup>

    <vs-popup :title="$t('comments')" :active.sync="showCommentSection">
      <AnswerCommentSection
        ref="commentsection"
      ></AnswerCommentSection>
    </vs-popup>

  </div>
</template>

<script>
import SimpleTextShow from '../answers/questions/show/SimpleTextShow'
import MultipleChoiceShow from '../answers/questions/show/MultipleChoiceShow'
import CheckBoxShow from '../answers/questions/show/CheckBoxShow'
import GapShow from '../answers/questions/show/GapShow'
import AnswerCommentSection from '../answers/AnswerCommentSection'
import QuestionnaireAnswerService from '@/services/api/QuestionnaireAnswerService'
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import CorrectionUserPage from './questionnaire_answers/CorrectionUserPage.vue'
import CorrectionQuestionPage from './questionnaire_answers/CorrectionQuestionPage.vue'
import QuestionCorrection from '@/mixins/QuestionCorrection'
import CorrectionCompleteQuestion from './questionnaire_answers/CorrectionCompleteQuestion.vue'
const VxTour = () => import('@/components/VxTour.vue')

export default {
  mixins: [QuestionCorrection],
  components: {
    AnswerCommentSection,
    VxTour,
    CorrectionUserPage,
    CorrectionQuestionPage,
    CorrectionCompleteQuestion
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    content: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    questionnaireAnswerService: null,
    contentQuestionnaireService: null,
    unfinishedCount: 0,
    totalAnswers: 0,
    questions: [],
    users: [],
    questionsFiltered: [],
    usersFiltered: [],
    usersList: [],
    questionGrouped: false,
    showCompleteQuestion: false,
    showCommentSection: false,
    completeQuestion: null,
    key: 0,
    filterCorrected: false,
    filteringAmount: 0,
    filterLock: false, //The need to use this is a sign that applyCorrectFilter must be refactored.
    pagination: {
      currentQuestion: 1,
      currentUser: 1,
    },
    grid: {
      total: 0,
      page: 0,
    }
  }),
  watch: {
    answerPage(val) {
      if (this.questionGrouped && val !== 0) {
        this.getAnswers(true)
      }
    },
    userPage(val) {
      if (!this.questionGrouped && val !== 0) {
        this.getAnswers(true)
      }
    },

    //this filter has quite the strange behavior, and it will now get worse because of a tiny feature...
    // This 'locking mechanism' only exists so that we can call applyCorrectFilter somewhere else
    // and have it bump (miss execution) in here.
    // TO-DO Refactor applyCorrectFilter, it is currently being used as a switch, and thats
    // not a great behavior to work with when we want to complement its behavior...
    filterCorrected(val) {
      if (!this.filterLock) {
        this.applyUncorrectedQuestionFilter(true)
      }
      this.filterLock = false
    },
    questionGrouped() {
      this.grid.page = 1
      this.grid.total = 1
      this.pagination.currentUser = 1
      this.getAnswers(true)
      this.applyCorrectFilter(true)
    },
    filteringAmount(val) {
      if (val === 0) {
        this.filterLock = true
        this.filterCorrected = false
        this.applyCorrectFilter(true)
      }
    }
  },
  computed: {
    contentQuestions() {
      return this._.get((JSON.parse(this._.get(this.content, 'data', ''))), 'fields', [])
    },
    hasRandomQuestions() {
      return this._.get(this.content, 'random_questions.type', false) || false
    },
    disableScoreReleaseButton() {
      const published_at = this._.get(this.content, 'published_at', '')
      return this.isEmpty(published_at) || this.totalAnswers <= 0
    },
    questionnaireClosed() {
      const finishAt = this.content && this.content.finish_at
      if (finishAt === null) {
        return false
      }
      const now = this.$moment()
      const then = this.$moment(finishAt)
      return then.isBefore(now)
    },
    answerPage() {
      return this.grid.page
    },
    userPage() {
      return this.pagination.currentUser
    },
    contentData() {
      return JSON.parse(this.content.data)
    },
    currentQuestion() {
      if (typeof this.questionsFiltered !== 'undefined' && this.questionsFiltered !== null) {
        return this.pagination.currentQuestion ? this.questionsFiltered[this.pagination.currentQuestion - 1] : this.questionsFiltered[0]
      }
      return null
    },
    currentUser() {
      return this.pagination.currentUser ? this.usersFiltered[0] : this.users[0]
    },
    currentQuestionTitle() {
      const title = this.questionsFiltered[this.pagination.currentQuestion - 1].data.title
      return this.isEmpty(title) ? '' : this.questionsFiltered[this.pagination.currentQuestion - 1].data.title.replace(/<\/?p[^>]*>/g, '')
    },
    noAnswers() {
      if (this.questionGrouped && this.questionsFiltered && this.questionsFiltered.length === 0) {
        return true
      } else if (!this.questionGrouped && this.usersFiltered && this.usersFiltered.length === 0) {
        return true
      }
      return false
    },
    hasAnswers() {
      const answersShowing = (
        (this._.isArray(this.questions) && this.questions.length) ||
        (this._.isArray(this.users) && this.users.length)
      ) || 0

      return answersShowing !== 0
    }
  },
  methods: {
    showCommentModal(answerData, correctionPage) {
      this.$refs.commentsection.getData(answerData.answer_id, answerData.question_id)
      if (correctionPage.comments.readCache.indexOf(answerData.question_id) === -1) {
        correctionPage.comments.readCache.push(answerData.question_id)
      }
      this.showCommentSection = true
    },
    showCompleteQuestionModal(dataAnswer) {
      this.key++
      this.completeQuestion = dataAnswer
      this.showCompleteQuestion = true
    },
    givenSecondChance() {
      this.questionGrouped = false
      this.getAnswers(true)
    },
    reapplyCorrection() {
      this.$vs.loading()
      this.contentQuestionnaireService.reapplyCorrection(this.id).then(
        response => {
          this.$vs.loading.close()
          if (this._.isNumber(response) && response > 0) {
            this.notifySuccess(this.$vs, this.$t('correcao-automatica-aplicada-em-response', [response]), 5000)
            this.getAnswers(true)
          } else {
            this.notifySuccess(this.$vs, this.$t('nao-ha-nenhuma-avaliacao-para-corrigir'), 5000)
          }
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    getShowQuestionPopupCardClass() {
      // return this.getRowClass(this.completeQuestion)
    },

    answerCorrectionWarnings(response) {
      const pendingCorrections = response && response.pending_correction
      const pendingScoreRelease = response && response.pending_score_release
      if (pendingCorrections && pendingScoreRelease) {
        this.notifyWarning(this.$vs, this.$t('pending_correction_and_release_msg'))
      } else if (pendingCorrections) {
        this.notifyWarning(this.$vs, this.$t('pending_correction_msg'))
      } else if (pendingScoreRelease) {
        this.notifyWarning(this.$vs, this.$t('pending_score_release_msg'))
      }
    },
    getAnswers(showLoading) {
      if (!this.isEmpty(this.id)) {
        if (showLoading) {
          this.$vs.loading()
        }

        if (this.questionGrouped) {
          this.questionnaireAnswerService.answersQuestion(this.id, {
            page: this.grid.page
          }).then(response => {
            this.grid.total = response.pagination && response.pagination.last_page
            this.grid.page = response.pagination && response.pagination.current_page
            this.answersCallback(response, showLoading)
          }, error => {
            if (showLoading) {
              this.$vs.loading.close()
            }
          })
        } else {
          this.questionnaireAnswerService.answersUser(this.id, {
            page: this.pagination.currentUser
          }).then(response => {
            this.grid.total = response.pagination && response.pagination.last_page
            this.grid.page = response.pagination && response.pagination.current_page
            this.answersCallback(response, showLoading)
          }, error => {
            if (showLoading) {
              this.$vs.loading.close()
            }
          })
        }

      }
    },
    answersCallback(response, showLoading) {
      // Response unpacking
      const groupQuestions = this._.get(response, 'grouped_by_question', []) || []
      const groupUsers = this._.get(response, 'grouped_by_user', []) || []
      const users = this._.get(response, 'users', []) || []
      const unfinishedAnswers = this._.get(response, 'unfinished_answers', 0) || 0
      const totalAnswers = this._.get(response, 'total_answers', 0) || 0

      // Data filling
      this.questions = groupQuestions
      this.questionsFiltered = this._.cloneDeep(this.questions)
      this.users = groupUsers
      this.usersFiltered = this._.cloneDeep(this.users)
      this.usersList = users
      this.unfinishedCount = parseInt(unfinishedAnswers)
      this.totalAnswers = parseInt(totalAnswers)

      // Control parameters
      this.pagination.currentQuestion = 1
      // this.pagination.currentUser = 1
      // this.comments.readCache = []

      // Setup methods
      this.answerCorrectionWarnings(response)
      this.applyCorrectFilter(true)

      // Tour logic to start only if there is at least some questions.
      const answerCount = (response['grouped_by_question'] || response['grouped_by_user'] || []).length
      if ((answerCount > 0) && !this.tourStarted('questionnaireAnswerHasAnswerSteps')) {
        this.startTour('questionnaireAnswerHasAnswerSteps')
      }

      // Stop loading animation if applicable.
      if (showLoading) {
        this.$vs.loading.close()
      }
    },
    questionCorrected(question, update) {
      console.log('corrected question')
      this.updateQuestion(question, update)

      this.applyCorrectFilter(false)
      this.showCompleteQuestion = false
      this.key++

    },
    updateQuestion(question, update) {
      const updated = {...question, ...update}

      if (this.users && this.users.length > 0) {
        this.users[0].answers.forEach((answer, i) => {
          const found = answer.findIndex((answeredQuestion) => {
            return answeredQuestion.answer_id === question.answer_id &&
              answeredQuestion.question_id === question.question_id
          })
          if (found !== -1) {
            this.users[0].answers[i][found] = updated
          }
        })
      }

      if (this.usersFiltered && this.usersFiltered.length > 0) {
        this.usersFiltered[0].answers.forEach((answer, i) => {
          const found = answer.findIndex((answeredQuestion) => {
            return answeredQuestion.answer_id === question.answer_id &&
              answeredQuestion.question_id === question.question_id
          })
          if (found !== -1) {
            this.usersFiltered[0].answers[i][found] = updated
          }
        })
      }

      if (this.questions && this.questions.length > 0) {
        this.questions.forEach((_question, i) => {
          const found = _question.answers.findIndex((answeredQuestion) => {
            return answeredQuestion.answer_id === question.answer_id &&
            answeredQuestion.question_id === question.question_id
          })
          if (found !== -1) {
            this.questions[i].answers[found] = updated
          }
        })
      }

      if (this.questionsFiltered && this.questionsFiltered.length > 0) {
        this.questionsFiltered.forEach((_question, i) => {
          const found = _question.answers.findIndex((answeredQuestion) => {
            return answeredQuestion.answer_id === question.answer_id &&
            answeredQuestion.question_id === question.question_id
          })
          if (found !== -1) {
            this.questionsFiltered[i].answers[found] = updated
          }
        })
      }

    },
    scoreReleaseConfirmation() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text:
          this.$t('tem-certeza-que-deseja-liberar-as-notas'),
        accept: this.scoreRelease
      })
    },
    scoreRelease() {
      this.$vs.loading()
      this.contentQuestionnaireService.scoreRelease(this.id).then(
        response => {
          this.content = response
          this.$emit('contentUpdated', response)
          this.$vs.loading.close()
          this.pendingCorrections = false
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    applyCorrectFilter(resetPagination) {

      if (resetPagination) {
        this.pagination.currentQuestion = 1
        // this.pagination.currentUser = 1
      }

      if (this.filterCorrected) {
        this.applyFilterCorrected()
      }
    },
    applyFilterCorrected() {
      const questions = []
      let index = 0
      this.questionsFiltered.forEach(question => {
        if (!this.isEmpty(question.answers)) {
          const answers = question.answers.filter(element => {
            return !('correction' in element)
          })

          if (answers.length > 0) {
            questions[index] = []
            questions[index].answers = answers
            questions[index].data = question.data
            index++
          }
        }
      })

      this.questionsFiltered = questions

      this.filteringAmount = questions.length

      this.usersFiltered.forEach((question, usersFilteredIndex, usersFilteredObject) => {

        if (!this.isEmpty(question.answers)) {
          const questionAnswers = []
          const answersWithoutCorrection = []
          question.answers.forEach((userAnswers, index, object) => {

            const answers = userAnswers.filter(element => {
              return !('correction' in element)
            })

            if (answers.length > 0) {
              answersWithoutCorrection[index] = []
              answersWithoutCorrection[index] = answers
            }

          })

          if (answersWithoutCorrection.length > 0) {
            question.answers = answersWithoutCorrection
          } else {
            usersFilteredObject.splice(usersFilteredIndex, 1)
          }
        }
      })
    },
    applyUncorrectedQuestionFilter(resetPagination) {
      if (resetPagination) {
        this.pagination.currentQuestion = 1
      }

      if (this.filterCorrected) {
        this.applyFilterCorrected()
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.questionGrouped) {
          this.questionsFiltered = this._.cloneDeep(this.questions)
        } else {
          this.usersFiltered = this._.cloneDeep(this.users)
        }
      }
    },
    setViewDefaults(questionnaire) {
      const randomQuestionType = this._.get(questionnaire, 'random_questions.type', null) || null
      if (randomQuestionType !== null) {
        this.questionGrouped = false
      }
    }
  },
  beforeMount() {
    this.questionnaireAnswerService = QuestionnaireAnswerService.build(this.$vs)
    this.contentQuestionnaireService = ContentQuestionnaireService.build(
      this.$vs
    )
  },
  mounted() {
    this.getAnswers(true)
    this.setViewDefaults(this.content)
    this.$root.$on('corrected', this.questionCorrected)
  },
  beforeDestroy() {
    this.$root.$off('corrected', this.questionCorrected)
    this.destroyChilds(this)
  }
}
</script>

<style>
.w-score .vs-con-input-label {
  width: 100px !important;
}
.dropdown-zfix {
  z-index: 60000;
}

.dropdown-zfix .vs-dropdown--item {
  z-index: 60001;
}

.select-auto-width.con-select {
  min-width: 200px !important;
  max-width: 350px !important;
  width: unset !important;
}
</style>
