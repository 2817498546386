<template>
  <div>
    <vx-card v-if="model"
      :key="model"
      class="mb-4"
    >
      <div slot="header" class="w-full">
        <slot name="header"></slot>
      </div>
      <p class="mb-3">
        <vs-table no-data=" " :data="model.answers" :key="key">
          <template slot="thead">
            <vs-th width="20%">{{ $t('participant') }}</vs-th>
            <vs-th width="18%">{{ $t('data-entrega') }}</vs-th>
            <vs-th width="43%">{{ $t('data_viewer.keys.answer') }}</vs-th>
            <vs-th width="1%">{{ $t('points') }}</vs-th>
            <vs-th width="18%">{{ $t('Actions') }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :class="getRowClass(data[i], 'question')"
              :state="correctionStatus(data[i])"
              :key="i"
              v-for="(tr, i) in data">
              <vs-td :data="data[i].user_name" v-tooltip="data[i].user_email">
                <label>{{data[i].user_name}}</label>
              </vs-td>
              <vs-td :data="data[i].finished_at">{{data[i].finished_at | moment('DD/MM/YYYY kk:mm')}}</vs-td>
              <vs-td class="answer" :data="data[i].answer">
                <question-answer :data="getCompleteQuestion(data[i])"/>
              </vs-td>
              <vs-td :data="data[i].score" class="w-score">
                <vs-input
                  lang="pt-BR"
                  type="number"
                  v-model="data[i].score"
                  :disabled="invalidatedQuestion(data[i].question_id)"
                  @input="scoreChanged(data[i], model.data.score)"
                />
              </vs-td>
              <vs-td :data="data[i].id">
                <div class="flex flex-wrap">
                  <vs-button
                    type="filled"
                    icon="check"
                    class="m-1 tour-answers-5"
                    :disabled="disableCorrectedButton(data[i])"
                    @click="correctQuestion(true, data[i])"
                    v-tooltip.top="{
                      content: 'Marcar questão como correta',
                      delay: { show: 1000, hide: 100 }
                    }"
                  ></vs-button>
                  <vs-button
                    type="filled"
                    color="danger"
                    icon="clear"
                    class="m-1 tour-answers-6"
                    :disabled="disableWrongButton(data[i])"
                    @click="correctQuestion(false, data[i])"
                    v-tooltip.top="{
                      content: 'Marcar questão como incorreta',
                      delay: { show: 1000, hide: 100 }
                    }"
                  ></vs-button>
                  <vs-button
                    type="filled"
                    color="warning"
                    icon="visibility"
                    class="m-1 tour-answers-7"
                    @click="showCompleteQuestionModal(data[i])"
                    v-tooltip.top="{
                      content: 'Visualizar questão completa',
                      delay: { show: 1000, hide: 100 }
                    }"
                  ></vs-button>

                  <div>
                    <feather-icon
                      @click="showCommentSectionModal(field)"
                      :badge="unreadComments(data[i].comments, data[i].question_id)"
                      class="float-right h-auto z-50 mr-3 mt-2 tour-answers-8"
                      svgClasses="text-success"
                    >
                    </feather-icon>
                    <vs-button
                      type="filled"
                      color="success"
                      icon="speaker_notes"
                      class="m-1"
                      @click="showCommentSectionModal(data[i])"
                      v-tooltip.top="{
                        content: 'Abrir comentários',
                        delay: { show: 1000, hide: 100 }
                      }"
                    ></vs-button>
                  </div>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="w-full justify-center flex flex-wrap" v-if="_.isArray(model.answers) && model.answers.length == 0">
          <label class="w-full text-center font-bold text-warning" v-if="hasRandomQuestions">
            {{ $t('este-questionario-possui-questoes-aleatorias') }}
          </label>
          <label class="w-full text-center">
            {{ $t('nao-ha-respostas-para-esta-questao') }}
          </label>
        </div>
        <div class="w-full pt-2 pb-8">
          <vs-pagination class="float-right" max="10" :total="grid.total" v-model="grid.page"></vs-pagination>
        </div>
      </p>
    </vx-card>
  </div>
</template>

<script>
import CorrectionPage from '@/mixins/CorrectionPage'
import QuestionCorrection from '@/mixins/QuestionCorrection'


export default {
  props: {
    grid: {
      type: Object,
      default: () => ({
        total: 0,
        page: 0,
      })
    }
  },
  mixins: [CorrectionPage, QuestionCorrection]
}
</script>

<style>

</style>
